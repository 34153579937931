import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { toggleSidebar } from "../../store";
import { Container, Header, Label, Menu, Icon, Image } from "semantic-ui-react";
import { MenuProps } from "../Menu";

interface HeaderMenuProps extends MenuProps {
  dispatch?: Dispatch<any>;
  inverted?: boolean;
}

export const HeaderMenu = ({ items, pathname, Link, inverted, dispatch }: HeaderMenuProps) =>
  <Container>
    <Menu size="large" secondary inverted={inverted}>
      <Menu.Item as="a" className="mobile only" icon="sidebar" onClick={() => dispatch && dispatch(toggleSidebar())}  />
      <Menu.Item className="mobile only align-center right">
        <Image
          size='medium'
          src="/static/images/surveillance-solutions-logo.png" />
      </Menu.Item>
      <Menu.Item className="mobile hidden">
        <Image
          size='medium'
          src="/static/images/surveillance-solutions-logo.png" />
      </Menu.Item>
      {items.map((item) => {
        const active = (item.exact) ? pathname === item.path : pathname.startsWith(item.path);
        return <Menu.Item
          as={Link}
          className="mobile hidden"
          name={item.name}
          to={item.path}
          key={item.path}
          active={active}
        />;
      })}
      <Menu.Item className="mobile hidden align-center right">
        <Icon name="phone" size="large" />
        <Header text inverted={inverted} as="h5">780-706-3100</Header>
      </Menu.Item>
    </Menu>
  </Container>;

export default connect()(HeaderMenu);
