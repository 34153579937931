import * as React from "react";
import { Link } from "gatsby";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import { Header, Container, Segment, Grid, Image, Icon } from "semantic-ui-react";
import { withLayout, LayoutProps, menuItems } from "../components/Layout";
import * as partner from "../../data/partner.json";

const AboutPage = (props: LayoutProps) => {
  return (
    <>
      <Container>
        <Segment vertical>
          <HeaderMenu
            Link={Link} pathname={props.location.pathname} items={menuItems}
          />
          <Header as="h2">
            <Icon name="info circle" />
            <Header.Content>
              Services
            </Header.Content>
          </Header>
        </Segment>
        {/* About this starter */}
        <Grid className="container stripe vertical" textAlign="center">
          <Grid.Row textAlign="center">
            <Header
              className="container"
              as="h3">Video Surveillance & Alarm Systems in Whitecourt & Surrounding Areas</Header>
            <p>
              At Surveillance Solutions, we service video surveillance and alarm systems in Whitecourt, Fox Creek, Edson, Swan Hills, Barrhead, Drayton Valley and surrounding areas. Our team of professionals can help you find the right alarm system and devices needed to keep your business and building secure. When you secure your premises with a trusted alarm system, you can sleep peacefully knowing that your industrial or commercial environment is monitored 24 hours a day. Individual pin numbers can be assigned to workers and/or employees to ensure effective security.
            </p>
          </Grid.Row>
        </Grid>

        {/* Key features */}
        <Segment vertical className="stripe alternate feature">
          <Grid
            divided
            doubling
            container
            columns="3"
            relaxed="very"
            direction="row"
            textAlign="center"
            verticalAlign="middle"
            className="container stripe margin-bottom">
            <Grid.Row>
              <Grid.Column>
                <Header as="h4">Door and window contacts</Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h4">Motion sensors</Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h4">Glass break detectors</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Header as="h4">Temperature-control devices</Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h4">Perimeter systems</Header>
              </Grid.Column>
              <Grid.Column>
                <Header as="h4">Smoke detectors</Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {/* About this starter */}
        <Grid className="container stripe vertical" textAlign="center">
          <Grid.Row textAlign="center">
            <Header
              className="container"
              as="h3">Video Surveillance Solutions</Header>
            <p>
              If you would like to keep an eye on your property remotely to make sure the building or commercial space is secure, as well as to stop theft and vandalism, Surveillance Solutions can set up surveillance cameras and the right software needed. We have experience in strategically placing cameras around your property to ensure that they will be viewing important areas such as the front and back doors, expensive equipment or products inside, and more.
            </p>
          </Grid.Row>
        </Grid>
        <Segment vertical className="stripe alternate feature">
          <Grid columns="3" textAlign="center" verticalAlign="middle" divided relaxed stackable className="container">
            <Grid.Column>
              <Image
                className="partner"
                src={partner[15].src}
                alt={partner[15].name}
                centered />
            </Grid.Column>
            <Grid.Column>
              <Image
                className="partner"
                src={partner[20].src}
                alt={partner[20].name}
                centered />
            </Grid.Column>
            <Grid.Column>
              <Image
                className="partner md"
                src={partner[23].src}
                alt={partner[23].name}
                centered />
            </Grid.Column>
          </Grid>
        </Segment>
      </Container>
    </>
  );
};

export default withLayout(AboutPage);
